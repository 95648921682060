@import '~antd/dist/antd.less';

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Inter', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
  overflow-y: hidden;
}

.ant-skeleton-element {
  width: 100%;
}

.ant-result {
  background-color: #f5f5f5;
  border-radius: 4px;
}

.ant-card-head, .ant-card, .ant-card-body {
  border-color: #d9d9d9;
}

*::-webkit-scrollbar-track-piece {
  background-color: #fafafa;
  -webkit-border-radius: 2em;
  -moz-border-radius: 2em;
  border-radius: 2em;
}

*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

*::-webkit-scrollbar-thumb {
  background-color: #ddd;
  background-clip: padding-box;
  -webkit-border-radius: 2em;
  -moz-border-radius: 2em;
  border-radius: 2em;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #a8a8a8;
}

.highcharts-credits {
  visibility: hidden;
}

.ant-picker-input > input {
  text-transform: capitalize;
}

.ant-select-arrow-loading .anticon.anticon-loading.anticon-spin {
  font-size: 20px;
  color: #676767;
}

.ant-col-24.ant-form-item-label {
  padding: 0;
}

.highcharts-title {
  font-size: 18px !important;
  font-weight: 400 !important;
}

.highcharts-axis-labels.highcharts-xaxis-labels {
  font-size: 14px;
}
@font-size-base: 12px;@primary-color: #1174c0;@layout-body-background: #fdfdfd;@component-background: #fff;@background-color-light: #fff;@zindex-popover: 1050;